import React from 'react'
import { CopyIcon, OpenInNewTabIcon } from './icons'

export interface EtherscanAddressProps {
    address: string
    fullAddress: string
}

const etherscanURL = 'https://etherscan.io/address/'
export function EtherscanAddress({ address, fullAddress }: EtherscanAddressProps) {
    return (
        <p className='flex flex-row items-center gap-2 m-0 font-semibold text-linkYellow'>
            {address}
            <span
                className='cursor-pointer'
                onClick={() => {
                    navigator.clipboard.writeText(fullAddress ?? address)
                }}>
                <CopyIcon />
            </span>

            <a
                href={`${etherscanURL}${fullAddress ?? address}`}
                target='_blank'
                rel='noreferrer'>
                <OpenInNewTabIcon />
            </a>
        </p>
    )
}

import React from 'react';
import classNames from 'classnames';

export interface CryptoIconProps {
  /** Icon name */
  name: string;
  /** Size modifier */
  size?: number;
  /** Tells the component if it is active */
  active?: boolean;
  /** Additional and optional classes */
  className?: string;
  /** Optional click handler */
  onClick?: () => void;
}

/**
 * Cryptocurrency icon
 */
export function CryptoIcon({
  name,
  className = '',
  size = 38,
  active = false,
  ...props
}: CryptoIconProps) {
  const iconClasses = classNames(
    'z-1',
    'opacity-100 filter grayscale group-hover:grayscale-0 ',
    { 'grayscale-0': active }
  );

  const iconStyles = {
    width: size,
    height: size,
    transition: 'filter 200ms ease-in',
    backgroundSize: 'cover',
    // TODO: Update bucket based in environment
    backgroundImage: `url(https://bucket91936-camilo.s3.us-east-2.amazonaws.com/${name.toLowerCase()}.png), url(https://i.imgur.com/af28ViY.png)`,
  };

  return (
    <div className="flex flex-col items-center justify-center w-max">
      <div
        {...props}
        style={{
          ...iconStyles,
        }}
        className={`${iconClasses} ${className}`}
      />
    </div>
  );
}

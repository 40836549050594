export const Intent = {
    DEFAULT: 'default' as 'default',
    PRIMARY: 'primary' as 'primary',
    SUCCESS: 'success' as 'success',
    WARNING: 'warning' as 'warning',
    DANGER: 'danger' as 'danger',
}

// eslint-disable-next-line
export type Intent = typeof Intent[keyof typeof Intent]

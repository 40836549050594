import React from 'react'

import { Intent } from './intent'

type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>

export interface InputProps extends HTMLInputProps {
    /** Value of the input field */
    value?: string
    /** Input type, defaults to 'text' */
    type?: 'text' | 'password' | 'textarea'
    /** Makes input fill its container, defaults to true */
    fill?: boolean
    /** Render an element to the right of input, EG a password show button */
    rightElement?: React.ReactElement
    /** Intent which controls border color */
    intent?: Intent
}

export const INPUT_CLASSES = {
    wrapper: [
        'flex border rounded-md',
        'focus-within:outline-none focus-within:ring-1 focus-within:ring-black focus-within:shadow-md',
    ],
    input: [
        'px-9 py-3 border-0 w-full',
        'bg-white bg-opacity-0 text-gray text-md font-light',
        'focus:outline-none',
    ],
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            placeholder,
            rightElement,
            type = 'text',
            fill = true,
            intent = Intent.DEFAULT,
            ...props
        },
        ref,
    ) => {
        const wrapperClasses = [
            ...INPUT_CLASSES.wrapper,
            `border-${intent}`,
            fill ? 'w-full' : '',
        ].join(' ')

        return (
            <div className={wrapperClasses}>
                {type === 'textarea' ? (
                    <textarea
                        ref={ref as any}
                        value={value}
                        className={INPUT_CLASSES.input.join(' ')}
                        placeholder={placeholder}
                        onChange={props.onChange as any}
                    />
                ) : (
                    <input
                        {...props}
                        ref={ref as any}
                        value={value}
                        className={INPUT_CLASSES.input.join(' ')}
                        type={type}
                        placeholder={placeholder}
                    />
                )}
                {rightElement}
            </div>
        )
    },
)

import React from 'react';

import { Spinner } from '../spinner';

export interface ButtonProps {
  /** Button contents */
  children: React.ReactNode;
  /** Button type defaults to 'button' */
  type?: 'button' | 'submit' | 'reset';
  /** Size modifier */
  size?: 'small' | 'large';
  /** Disables the button */
  disabled?: boolean;
  /** Renders a spinner */
  loading?: boolean;
  /** Renders the button full width */
  fill?: boolean;
  /** Renders without a background color */
  minimal?: boolean;
  /** Any additional classes required for styling */
  className?: string;
  /** Optional click handler */
  onClick?: (e?: any) => void;
  /** Optional Styles */
  style?: any;
  /** Optional reference to the button */
  ref?: any;
}

/**
 * Default button component for click interactions
 */
export function Button({
  children,
  size,
  className,
  type = 'button',
  fill = false,
  minimal = false,
  disabled = false,
  loading = false,
  ...props
}: ButtonProps) {
  const buttonClasses = [
    'inline-flex items-center justify-center',
    'px-12 py-2',
    'rounded-none',
    minimal ? '' : 'text-white',
    minimal ? '' : 'bg-black',
    'focus:outline-none focus:ring focus:ring-blue',
    fill ? 'w-full' : '',
    size === 'large' ? 'text-lg py-4' : '',
    size === 'small' ? 'text-sm' : '',
    disabled ? 'opacity-50 cursor-not-allowed' : '',
    loading ? 'cursor-not-allowed' : '',
    className,
  ].join(' ');

  const textClasses = [loading ? 'ml-3' : ''].join(' ');

  return (
    <button
      {...props}
      type={type}
      className={buttonClasses}
      onClick={disabled ? () => {} : props.onClick}
    >
      {loading ? <Spinner /> : null}
      <span className={textClasses}>{children}</span>
    </button>
  );
}

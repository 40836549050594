import React from 'react';
export const CopyIcon = () => {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M10.434.587h-7c-.641 0-1.166.526-1.166 1.17v8.19h1.166v-8.19h7V.587zm1.75 2.34H5.768c-.642 0-1.167.527-1.167 1.17v8.19c0 .644.525 1.17 1.167 1.17h6.417c.641 0 1.166-.526 1.166-1.17v-8.19c0-.643-.525-1.17-1.167-1.17zm0 9.36H5.768v-8.19h6.417v8.19z"
          fill="#404040"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(.81 .002)"
            d="M0 0h14v14.041H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OpenInNewTabIcon = () => {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.893 11.117H3.726v-8.19H7.81v-1.17H3.726c-.647 0-1.166.526-1.166 1.17v8.19c0 .644.519 1.17 1.166 1.17h8.167c.642 0 1.167-.526 1.167-1.17V7.022h-1.167v4.095zm-2.917-9.36v1.17h2.094l-5.734 5.75.823.826 5.734-5.751v2.1h1.167V1.757H8.976z"
        fill="#404040"
      />
    </svg>
  );
};

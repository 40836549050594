import React from 'react';
export interface TableProps {
    headers?: string[]
    items: any
}
export function Table({
    headers = [
        'Asset',
        'Pool Name',
        'Pool Value',
        'Utilization',
        'Interest Period',
        'Rates',
    ],
    items,
}: TableProps) {
    const tableClasses = [
        `flex flex-col justify-start gap-y-2 overflow-scroll flex-grow scrollbar-hide`,
    ].join(' ')

    const headerClassNames =
        'not-italic font-light text-sm leading-3 text-gray pb-4'

    return (
        <div className={tableClasses}>
            <table className='w-full mt-4 table-auto'>
                <thead className='border-b border-gray2'>
                    {headers.map(h => (
                        <th className={headerClassNames}>{h}</th>
                    ))}
                </thead>
                <tbody data-test-id='transaction-items-table'>{items}</tbody>
            </table>
        </div>
    )
}
